/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import Cookies from 'js-cookie';
import focusLock from 'dom-focus-lock';

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease'
});

// Headroom
import Headroom from "headroom.js";
var myElement = document.querySelector("header");
var headroom = new Headroom(myElement);
headroom.init();

// Fader
var faderSliders = document.querySelectorAll('.fader');
if (faderSliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		faderSliders.forEach(el => {
			var slides = el.querySelectorAll('.swiper-slide');

			if (slides.length > 1) {
				var swiperPagination = document.createElement('div');
				swiperPagination.setAttribute('class', 'swiper-pagination is-vertical');
				el.querySelector('.swiper-container').appendChild(swiperPagination);

				new Swiper(el.querySelector('.swiper-container'), {
					effect: "fade",
					watchOverflow: true,
					autoplay: {
						delay: 2500,
						disableOnInteraction: false
					},
					speed: 1500,
					slidesPerView: 1,
					keyboard: {
						enabled: true
					},
					pagination: {
						el: el.querySelector('.swiper-pagination'),
						type: 'bullets',
						clickable: true
					}
				});
			}
		});
	})();
}

// Instagram sliders
var instagramSliders = document.querySelectorAll('.activities-intragram-block__instagram');
if (instagramSliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		instagramSliders.forEach(el => {
			var swiperPagination = document.createElement('div');
			var instagramContent = el.querySelector('.activities-intragram-block__instagram-content');
			swiperPagination.setAttribute('class', 'swiper-pagination is-horizontal');
			instagramContent.insertBefore(swiperPagination, instagramContent.firstChild);

			new Swiper(el.querySelector('.swiper-container'), {
				effect: "fade",
				watchOverflow: true,
				autoplay: {
					delay: 2500,
					disableOnInteraction: true
				},
				speed: 1500,
				slidesPerView: 1,
				keyboard: {
					enabled: true
				},
				pagination: {
					el: el.querySelector('.swiper-pagination'),
					type: 'bullets',
					clickable: true
				}
			});
		});
	})();
}

//Image carousel
var newsSwiper = document.querySelectorAll('.content-block.is-image-carousel-block, .content-block.is-home-image-carousel-block');
if (newsSwiper.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		newsSwiper.forEach(el => {
			const columnBlock = el.querySelector('.columns'),
				swiperItem = columnBlock.querySelectorAll('.column'),
				swiperContainer = document.createElement('div'),
				swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div'),
				swiperButtons = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);

			swiperButtons.setAttribute('class', 'swiper-buttons');
			swiperButtonPrev.setAttribute('class', 'swiper-button-next');
			swiperButtonNext.setAttribute('class', 'swiper-button-prev');
			swiperButtons.appendChild(swiperButtonNext);
			swiperButtons.appendChild(swiperButtonPrev);
			swiperContainer.appendChild(swiperButtons);

			swiperItem.forEach(si => {
				si.setAttribute('class', 'swiper-slide');
			});

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: swiperButtonPrev,
					prevEl: swiperButtonNext
				}
			});
		});
	})();
}

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('side-pop-up'), navigation = document.querySelector('.side-pop-up'), navigationContainer = document.querySelector('.side-pop-up__container');
if (navigationToggler && navigation && navigationContainer) {

	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigationContainer);
			document.documentElement.classList.add('has-no-overflow');
		} else {
			focusLock.off(navigationContainer);
			document.documentElement.classList.remove('has-no-overflow');
		}
	};

	navigation.addEventListener('click', e => {
		if (!navigationContainer.contains(e.target)) {
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '2560px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Paged images
async function loadWaypoints(overviews, itemCss) {

	overviews.forEach(el => {

		const linkEl = el.querySelector('.page-link');
		const appendEl = el.querySelector('.columns');
		const pagingContainer = el.querySelector('.pagination-container');

		if (linkEl && appendEl) {

			linkEl.addEventListener('click', async e => {
				e.preventDefault();

				const url = linkEl.getAttribute('href');
				linkEl.removeAttribute('href');

				if (url) {
					const LazyPager = (await import('./modules/lazy-pager.js')).default;

					new LazyPager(el, url, {
						observe: appendEl,
						append: appendEl,
						itemToAdd: itemCss,
						linkCssClass: '.page-link',
						pagingContainer: pagingContainer,
						onLoad: () => {
							linkEl.classList.add('loading');
						},
						onLoaded: (el, nextPageUrl) => {
							linkEl.classList.remove('loading');

							if (!nextPageUrl) {
								// Remove link
								pagingContainer.remove();
							}
						}
					});
				}
			});
		}
	});
}

var mediaOverviews = document.querySelectorAll('.content-block.is-album-block');
if (mediaOverviews.length) {
	loadWaypoints(mediaOverviews, '.content-block.is-album-block .column');
}

var shopsOverview = document.querySelectorAll('.content-block.is-shop-overview');
if (shopsOverview.length) {
	loadWaypoints(shopsOverview, '.column');
}

//Filter
const productFilter = document.querySelector('.filter');
const overview = document.querySelector('.shops');
if (productFilter && overview) {

	// Get all the inputs
	var inputs = productFilter.querySelectorAll('input.filter__input');
	inputs.forEach(el => {

		// Add on change event to inputs
		el.addEventListener("change", async function (e) {

			// Remove parent checked input, if child input is selected
			if (el.classList.contains("is-child-filter") && el.checked) {
				var parentElement = el.parentElement.parentElement.querySelector('.filter__list-title .filter__input');
				parentElement.checked = false;
			}

			// Get the checked inputs from the form
			const data = new URLSearchParams(new FormData(productFilter));

			// Get new overview
			fetch(window.location.pathname, {
				method: "post",
				body: data,
				headers: {
					'X-Requested-With': 'XMLHttpRequest'
				}
			})
				.then(response => response.text())
				.then(data => {

					// Empty overview
					overview.innerHTML = '';

					// Create DOM
					var parser = new DOMParser();
					var doc = parser.parseFromString(data, 'text/html');

					// Add items with animation
					doc.querySelectorAll('.column').forEach(function (child, i) {
						child.classList.add("is-hidden");
						overview.appendChild(child);
						setTimeout(function () { child.classList.remove('is-hidden'); }, 40 * i);
						i++;
					});

					var paginationContainer = doc.querySelector('.pagination-container');
					if (paginationContainer) {
						overview.appendChild(paginationContainer);
					}

					// Add infini scroll if nescirry
					var shopsOverview = document.querySelectorAll('.content-block.is-shop-overview');
					if (shopsOverview.length && document.querySelectorAll('.content-block.is-shop-overview .button').length) {
						loadWaypoints(shopsOverview, '.column');
					}
				});

			// Update the url
			if (history.pushState) {

				if (data.toString() === "") {
					window.history.replaceState({}, '', `${location.pathname}`);
				}
				else {
					window.history.replaceState({}, '', `${location.pathname}?${data}`);
				}
			}

			e.preventDefault();
		});
	});
}

// Toasts
if (window.toasts) {
	const toastsCookieName = 'toasts',
		toastsCookieOptions = {
			sameSite: 'strict',
			secure: true
		};

	if (window.toasts.length) {
		(async () => {
			const Toasts = (await import('./modules/toasts.js')).default;
			new Toasts(window.toasts, toastsCookieName, toastsCookieOptions);
		})();
	} else {
		// Delete cookie if there are no active toasts
		Cookies.remove(toastsCookieName, toastsCookieOptions);
	}
}

// Focus search input
const searchToggler = document.getElementById('search-form__toggler'), searchInput = document.getElementById('search-form__input');
if (searchToggler && searchInput) {
	searchToggler.addEventListener('change', () => {
		if (searchToggler.checked) {
			// Add delay because of animation that uses visisiblity style
			setTimeout(function () { searchInput.focus(); }, 310);
		}
	});
}

// Jump to submitted form
document.querySelectorAll('.content-block.is-shop-overview').forEach(el => {
	var form = el.querySelector('form'),
		anchor = el.querySelector('.anchor'),
		button = el.querySelector('.button');

	if (form) {
		if (!anchor) {
			anchor = el.querySelector('.form').getAttribute('id');
		}
		else {
			anchor = anchor.getAttribute('id');
		}

		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});
	}
});

// Modal overview
var modalContainers = document.querySelectorAll('.modal-overview');
var activeModalContainer = null;
var blockClickEvent = false;

if (modalContainers.length) {

	modalContainers.forEach(el => {
		var culture = document.documentElement.lang;

		el.addEventListener('click', function(event) {
			if (!blockClickEvent) { 
				var modalButton = event.target.closest('.button.is-modal-button');
				if (modalButton) { 
					var modalItem = modalButton.closest('.modal-item');

					if (modalItem) {
						getModal('/umbraco/Surface/Modal/Info?id=' + modalItem.id + '&culture=' + culture, el);
					}
				}
			}
		});
	});
}

window.addEventListener('click', function (event) {
	if (!event.target.closest('.modal__content') && activeModalContainer && !blockClickEvent) {
		closeModal(event, activeModalContainer);
	}
});

function getModal(fetchUrl, overview) {

	fetch(fetchUrl, {
		method: 'get',
		headers: {
			'X-Requested-With': 'XMLHttpRequest'
		}
	})
		.then(response => response.text())
		.then(data => {
			var parser = new DOMParser();
			var newModal = parser.parseFromString(data, 'text/html').querySelector('.modal');
			createModal(newModal, overview);
		});
}

function createModal(newModal, overview) {

	if (activeModalContainer) {
		blockClickEvent = true;
		var oldModal = activeModalContainer.querySelector('.modal');
		oldModal.classList.remove('fade-in');
		newModal.classList.remove('fade-in');
		setTimeout(function () { oldModal.remove(); activeModalContainer.appendChild(newModal); newModal.classList.add('fade-in'); blockClickEvent = false; }, 250);
	}
	else {
		activeModalContainer = document.createElement('div');
		activeModalContainer.classList.add('modal-container');
		document.body.appendChild(activeModalContainer);
		activeModalContainer.appendChild(newModal);

		setTimeout(function () { activeModalContainer.classList.add('fade-in'); }, 250);

		activeModalContainer.addEventListener('click', event => {
			if (event.target === activeModalContainer) {
				closeModal(event, activeModalContainer);
			}
			if (event.target.className === 'modal__close') {
				closeModal(event, activeModalContainer);
			}
			if (event.target.className === 'modal__prev') {
				getSiblingModal(event, activeModalContainer, overview, false);
			}
			if (event.target.className === 'modal__next') {
				getSiblingModal(event, activeModalContainer, overview, true);
			}
			if (event.target.className === 'swiper-button-prev' || event.target.className === 'swiper-button-next') {
				getSiblingModal(event, activeModalContainer, overview, true);
			}
		});

		document.addEventListener('keydown', event => {
			if (event.keyCode === 27) {
				closeModal(event, activeModalContainer);
			}
		});
	}
}

function closeModal(e, modalContainer) {
	var modal = modalContainer.querySelector('.modal');
	modal.classList.remove('fade-in');
	setTimeout(function () { modalContainer.classList.remove('fade-in') }, 100);
	setTimeout(function () { modalContainer.remove(); }, 150);
	activeModalContainer = null;
}

function getSiblingModal(e, modalContainer, overview, getNext) {
	var modal = modalContainer.querySelector('.modal');
	var id = modal.dataset.modalId;
	var modalItems = overview.querySelectorAll('.modal-item');
	var modalItemsCount = modalItems.length;
	var currentModelItemIndex = 0;
	var nextModalItemToShow = null;

	for (var i = 0; i < modalItemsCount; i++) {
		if (modalItems[i].id === id) {
			currentModelItemIndex = i;
		}
	}

	if (getNext) {
		nextModalItemToShow = modalItems[currentModelItemIndex + 1];
		if (!nextModalItemToShow) {
			nextModalItemToShow = modalItems[0];
		}
	}
	else {
		nextModalItemToShow = modalItems[currentModelItemIndex - 1];
		if (!nextModalItemToShow) {
			nextModalItemToShow = modalItems[(modalItemsCount - 1)];
		}
	}

	if (nextModalItemToShow !== null) {
		getModal('/umbraco/Surface/Modal/Info?id=' + nextModalItemToShow.id + '&culture=' + document.documentElement.lang, overview);
	}
}